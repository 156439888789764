import React, { useState, createContext, useContext, useEffect } from 'react'
import { useWindowSize } from '../../hooks/windowSize'
export const ResponsiveContext = createContext()

function ResponsiveContextProvider({ children }) {
  // screen width responsive
  const width = useWindowSize().width
  const handleScreenWidth = () => {
    if (width <= 450) {
      setSummaryCardColSpan(24)
      setTransactionColSpan(24)
      setYtdCardColSpan(24)
      setSubHeaderSize('9px')
      // ---- end of 450 ----
    } else if (width > 450 && width <= 650) {
      setSummaryCardColSpan(24)
      setTransactionColSpan(24)
      setYtdCardColSpan(8)
      setSubHeaderSize('10px')
      // ---- end of 650 ----
    } else if (width > 650 && width < 900) {
      setSummaryCardColSpan(8)
      setTransactionColSpan(24)
      setYtdCardColSpan(8)
      setSubHeaderSize('11px')
      // ---- end of 900 ----
    } else {
      setSummaryCardColSpan(8)
      setTransactionColSpan(12)
      setYtdCardColSpan(8)
      setSubHeaderSize('12px')
    }
  }

  const [subHeaderSize, setSubHeaderSize] = useState('12px')

  // summary card
  const [summaryCardColSpan, setSummaryCardColSpan] = useState(8)

  const [transactionColSpan, setTransactionColSpan] = useState(12)
  const [ytdCardColSpan, setYtdCardColSpan] = useState(8)

  useEffect(() => {
    handleScreenWidth()
    // eslint-disable-next-line
  }, [width])
  const value = {
    summaryCardColSpan,
    transactionColSpan,
    ytdCardColSpan,
    subHeaderSize,
  }
  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => useContext(ResponsiveContext)
export const ResponsiveConsumer = ResponsiveContext.Consumer
export default ResponsiveContextProvider
