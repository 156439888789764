import React from 'react'
import { Route, Routes } from 'react-router-dom'

// import components
import Home from '../pages/Home'
import Stamp from '../pages/Stamp'
import StampHistory from '../pages/StampHistory'
import Leave from '../pages/Leave'
import Slip from '../pages/Slip'
import Profile from '../pages/accounts/Profiles'
import ChangePassword from '../pages/accounts/ChangePassword'

export default function Private() {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route path='/stamp' element={<Stamp />} />
      <Route path='/stamp-history' element={<StampHistory />} />
      <Route path='/leave' element={<Leave />} />
      <Route path='/slip' element={<Slip />} />
      <Route path='/accounts/profiles' element={<Profile />} />
      <Route path='/accounts/change-password' element={<ChangePassword />} />
    </Routes>
  )
}
