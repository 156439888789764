import { useState, useEffect, useRef } from 'react'

// Hook
export const useWindowSize = () => {
  const isClient = typeof window === 'object' //Object represents browser window
  const lastWidth = useRef()

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    } //Exit if not user/browser

    function handleResize() {
      if (window?.innerWidth !== lastWidth.current) {
        const width = getSize()
        lastWidth.current = width
        setWindowSize(width)
      }
    }
    window.addEventListener('resize', handleResize) // <-- I am only interested in window.innerWidth !
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, [])

  return windowSize
}
