const { EncryptStorage } = require('storage-encryption')

const encryptStorage = new EncryptStorage('PCT@GROUP', 'localStorage')

module.exports = {
  localStorageEncrypt: (key, value) => {
    return encryptStorage.encrypt(key, value)
  },

  localStorageDecrypt: (key) => {
    return encryptStorage.decrypt(key)
  },

  removeKey: (key) => {
    return encryptStorage.remove(key)
  },
}
