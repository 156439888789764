import React, { useEffect, useState, createContext } from 'react'
import { Row, Col, Divider, Modal, Input, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import axios from '../library/Axios'

import { warning } from '../components/Notification'

// icons
import {
  CheckCircleOutline,
  RemoveCircleOutline,
  AttachMoneyOutlined,
} from '@material-ui/icons'

// style
import '../styles/slip.css'

// helpers
import { getPeriodString, getFullDate } from '../helpers/DateTime'

// components
import SummaryCard from '../components/Slip/SummaryCard'
import HeaderTitle from '../components/Slip/HeaderTitle'
import TransactionCard from '../components/Slip/TransactionCard'
import SumYTDCard from '../components/Slip/SumYTDCard'
import PrintComponent from '../components/Slip/PrintComponent'
import SelectPeriod from '../components/Slip/SelectPeriod'

// context
import { useAuth } from '../context/Auth'
import ResponsiveContextProvider, {
  ResponsiveConsumer,
} from '../context/responsive/Slip'
import { localStorageDecrypt } from '../library/Storage'

const SlipContext = createContext()
const Slip = () => {
  const navigate = useNavigate()
  const { user, setLogout } = useAuth()

  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(false)

  // Over Time date start-end
  const [startOT, setSartOT] = useState('')
  const [endOT, setEndOT] = useState('')

  const fetchAllTransaction = async (s) => {
    if (s.length !== 3) return
    let transaction = []
    setLoading(true)
    axios
      .post(
        '/slip',
        {
          year: s[0],
          month: s[1],
          period: s[2],
          personId: user.PersonID,
        },
        {
          headers: {
            Authorization: `bearer ${localStorageDecrypt('token')}`,
          },
        }
      )
      .then((results) => {
        setSelected(true)
        transaction = results.data.map((el) => {
          return {
            key: el.IncomeAuto,
            ...el,
          }
        })
        const filterZero = transaction.filter((item) => item.Amount !== 0)
        const incomeFilter = filterZero.filter((item) => item.Type === 'A')

        // set deduct transaction
        setDeduct(filterZero.filter((item) => item.Type === 'E'))

        // sort income
        setIncome(
          incomeFilter.sort(
            (firstEl, secondEl) => secondEl.Amount - firstEl.Amount
          )
        )
        // set grand total
        setGrandTotal(
          transaction.find((item) => item.IncomeAuto === 'A0').Amount
        )

        // set summary
        setIncomeSummary(transaction[0].SumIncome)
        setDeductSummary(transaction[0].SumDeduct)

        // YTD Card
        setYTDIncome(transaction[0].SumYTDIncome)
        setYTDSSF(transaction[0].SumYTDSSF)
        setYTDTax(transaction[0].SumYTDTax)

        // pay date and period string

        setPeriodString(getPeriodString(transaction[0].PayDate, s[2]))
        setPayDate(getFullDate(transaction[0].PayDate))

        setSartOT(transaction[0].dateEndOT)
        setEndOT(transaction[0].dateStartOT)

        setTimeout(() => {
          setLoading(false)
        }, 800)
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false)
        }, 800)
        setSelected(false)

        return warning(
          'ไม่พบข้อมูล',
          'กรุณารอการประมวลผล หรือคุณอาจไม่มีการจ่ายเงินเดือนในงวดนี้ หากมีข้อสงสัยกรุณาติดต่อส่วนกลาง เพื่อทำการตรวจสอบอีกครั้ง'
        )
      })
  }

  // summary card
  const [incomeSummary, setIncomeSummary] = useState(0)
  const [deductSummary, setDeductSummary] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)

  // transaction
  const [income, setIncome] = useState([])
  const [deduct, setDeduct] = useState([])

  // YTD Card
  const [YTDIncome, setYTDIncome] = useState(0)
  const [YTDTax, setYTDTax] = useState(0)
  const [YTDSSF, setYTDSSF] = useState(0)

  // pay date
  const [payDate, setPayDate] = useState('')

  // eslint-disable-next-line
  const [periodString, setPeriodString] = useState('')
  // for slip form
  const value = {
    income,
    deduct,
    grandTotal,
    incomeSummary,
    deductSummary,
    YTDIncome,
    YTDTax,
    YTDSSF,
    payDate,
    periodString,
    startOT,
    endOT,
  }

  const contentWithAuthenticated = () => (
    <ResponsiveContextProvider>
      <ResponsiveConsumer>
        {({
          summaryCardColSpan,
          iconSize,
          transactionColSpan,
          ytdCardColSpan,
          subHeaderSize,
        }) => {
          const iconStyle = {
            fontSize: '60px',
            position: 'absolute',
            opacity: '0.5',
            bottom: 17,
            left: 10,
          }
          return (
            <div
              style={{
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '8px',
              }}>
              <Row justify={'start'}>
                <Col>
                  <HeaderTitle subHeaderSize={subHeaderSize} />
                </Col>
                <Divider style={{ margin: '10px 0' }} />
              </Row>
              <Row gutter={[12, 12]} style={{ marginBottom: '15px' }}>
                <Col span={24}>
                  <SelectPeriod handlePeriodChange={fetchAllTransaction} />
                </Col>
              </Row>
              {selected ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <>
                    <Row gutter={[12, 12]} style={{ marginBottom: '15px' }}>
                      <Col span={summaryCardColSpan}>
                        <SummaryCard
                          textColor={'#389e0d'}
                          title={'รวมรายได้'}
                          amount={incomeSummary}
                          icon={<CheckCircleOutline style={iconStyle} />}
                        />
                      </Col>
                      <Col span={summaryCardColSpan}>
                        <SummaryCard
                          textColor={'#cf1322'}
                          title={'รวมรายหัก'}
                          amount={deductSummary}
                          icon={<RemoveCircleOutline style={iconStyle} />}
                        />
                      </Col>
                      <Col span={summaryCardColSpan}>
                        <SummaryCard
                          textColor={'#096dd9'}
                          title={'รายได้สุทธิ'}
                          amount={grandTotal}
                          icon={<AttachMoneyOutlined style={iconStyle} />}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[12, 12]} style={{ marginBottom: '15px' }}>
                      <Col span={transactionColSpan}>
                        <TransactionCard
                          title={'รายการได้'}
                          color={'success'}
                          transaction={income}
                        />
                      </Col>
                      <Col span={transactionColSpan}>
                        <TransactionCard
                          title={'รายการหัก'}
                          color={'error'}
                          transaction={deduct}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[20, 20]} style={{ marginBottom: '15px' }}>
                      <Col span={ytdCardColSpan}>
                        <SumYTDCard title={'รายได้สะสม'} amount={YTDIncome} />
                      </Col>
                      <Col span={ytdCardColSpan}>
                        <SumYTDCard title={'ภาษีสะสม'} amount={YTDTax} />
                      </Col>
                      <Col span={ytdCardColSpan}>
                        <SumYTDCard title={'ประกันสังคมสะสม'} amount={YTDSSF} />
                      </Col>
                    </Row>
                    <Row justify='center'>
                      <Col span={24}>
                        <SlipContext.Provider value={value}>
                          <PrintComponent />
                        </SlipContext.Provider>
                      </Col>
                    </Row>
                  </>
                )
              ) : (
                ''
              )}
            </div>
          )
        }}
      </ResponsiveConsumer>
    </ResponsiveContextProvider>
  )

  // two factor authentication
  const [passwordAttempt, setPasswordAttempt] = useState(0)
  useEffect(() => {
    const handlePasswordAttempt = () => {
      if (passwordAttempt >= 5) {
        setLogout()
      }
    }
    handlePasswordAttempt()
    // eslint-disable-next-line
  }, [passwordAttempt])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [periodNotFound, setPeriodNotFound] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    if (!!passwordViewSlip) {
      if (passwordViewSlip === user.Pws) {
        setIsModalVisible(false)
        setAuthenticated(true)
        setPasswordViewSlip('')
        alertMessage()
      } else {
        setPasswordAttempt((prev) => (prev += 1))
        setInvalidPassword(true)
      }
    } else {
      warning('แจ้งเตือน', `กรุณากรอกรหัสผ่านให้ครบก่อนกดปุ่ม 'ตกลง' `)
    }
  }
  const handleCancel = () => {
    navigate('/')
  }
  const handlePasswordViewSlipChange = (val) => {
    setInvalidPassword(false)
    setPasswordViewSlip(val)
  }
  const [passwordViewSlip, setPasswordViewSlip] = useState('')
  const [invalidPassword, setInvalidPassword] = useState(false)
  useEffect(() => {
    let mounted = false
    const handleCheckPeriod = () => {
      if (!mounted) {
        axios
          .get(`/get-period/${localStorageDecrypt('personId')}`, {
            headers: {
              Authorization: `bearer ${localStorageDecrypt('token')}`,
            },
          })
          .then((results) => {
            if (results.data.length > 0) {
              setPeriodNotFound(false)
              showModal(true)
            }
          })
          .catch((err) => {
            setPeriodNotFound(true)
          })
      }
    }
    handleCheckPeriod()
    return () => {
      mounted = true
    }
    // eslint-disable-next-line
  }, [])

  const alertMessage = () => {
    Modal.warning({
      className: 'modal-alert-message-box-slip',
      centered: true,
      title: <h4>คำเตือน!</h4>,
      content: (
        <>
          <p style={{ fontSize: '12px' }}>
            ใบแจ้งเงินเดือนถือเป็นความลับและมีวัตถุประสงค์เพื่อแจ้งยอดเงินผลตอบแทนของพนักงานที่ระบุชื่อบนระบบเพียงผู้เดียวเท่านั้น
          </p>
          <p style={{ fontSize: '12px' }}>
            หากข้อมูลมีความผิดปกติใด ๆ กรุณาติดต่อส่วนกลาง
            การเผยแพร่หรือการใช้งานอื่น ๆ หรือการกระทำใด ๆ
            โดยอาศัยข้อมูลนี้โดยบุคคลหรือหน่วยงานอื่น ๆ
            ที่ไม่ใช่ผู้รับถือเป็นสิ่งต้องห้าม
          </p>
        </>
      ),
      okText: 'ปิด',
      okButtonProps: {
        style: {
          borderRadius: '8px',
        },
      },
    })
  }
  return (
    <>
      <Modal
        width={320}
        centered={true}
        className='modal-confirm-password'
        visible={isModalVisible}
        onOk={handleOk}
        okText='ตกลง'
        okButtonProps={{
          style: { borderRadius: '8px' },
        }}
        onCancel={handleCancel}
        cancelText='กลับหน้าแรก'
        cancelButtonProps={{
          type: 'text',
          style: { color: '#ccc', borderRadius: '8px' },
        }}>
        <Row>
          <h4 style={{ fontWeight: 'bold', letterSpacing: '0.6px' }}>
            ยืนยันรหัสผ่าน
          </h4>
        </Row>
        <Row>
          <Input.Password
            type='password'
            onChange={(e) => handlePasswordViewSlipChange(e.target.value)}
            placeholder='รหัสผ่าน'
          />
        </Row>
        {invalidPassword ? (
          <>
            <span
              style={{
                color: 'red',
                fontSize: '9px',
              }}>
              *รหัสผ่านผิดพลาด กรุณาลองใหม่อีกครั้ง
            </span>
            <br />
          </>
        ) : (
          ''
        )}
        {passwordAttempt >= 2 ? (
          <span style={{ color: 'red', fontSize: '9px' }}>
            *คุณกรอกรหัสผ่านผิดแล้ว {passwordAttempt} ครั้ง หากเกิน 5
            ครั้งคุณจะออกจากระบบอัตโนมัติ
          </span>
        ) : (
          ''
        )}
      </Modal>
      <Modal
        width={320}
        centered={true}
        className='modal-comming-soon'
        visible={periodNotFound}
        onOk={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        cancelText='กลับหน้าแรก'
        cancelButtonProps={{
          type: 'primary',
          style: { borderRadius: '8px' },
        }}>
        สลิปเงินเดือนยังไม่เปิดให้ใช้งาน กรุณาติดต่อส่วนกลาง
      </Modal>
      {authenticated ? contentWithAuthenticated() : ''}
    </>
  )
}

export const SlipConsumer = SlipContext.Consumer
export default Slip
