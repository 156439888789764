import React, { createContext, useState, useContext } from 'react'
import './styles/App.css'
import 'antd/dist/antd.css'
import { BrowserRouter } from 'react-router-dom'

// component
import Login from './layouts/public/Login'

// context
import AuthContextProvider, { AuthConsumer } from './context/Auth'

// layouts
import MainLayout from './layouts/private/'

const SiderContext = createContext()

function App() {
  const [collapsed, setCollapsed] = useState(false)
  const value = { collapsed, setCollapsed }

  return (
    <div className='App'>
      <BrowserRouter>
        <AuthContextProvider>
          <AuthConsumer>
            {({ loggedIn }) => {
              if (loggedIn) {
                return (
                  <>
                    <SiderContext.Provider value={value}>
                      <MainLayout />
                    </SiderContext.Provider>
                  </>
                )
              } else {
                return <Login />
              }
            }}
          </AuthConsumer>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  )
}
export const useSiderContext = () => useContext(SiderContext)
export const SiderConsumer = SiderContext.Consumer
export default App
