/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Card, Row, Typography, Table, Button, Modal, Badge } from 'antd'
import { EyeOutlined, NotificationOutlined } from '@ant-design/icons'
import { Attachment } from '@material-ui/icons'
import { getFullDate } from '../../helpers/DateTime'

const NewsCard = ({ data, cardStyle }) => {
  const info = ({ Title, Description, Created_At, Created_By, Link_Url }) => {
    if (!!Title && !!Description && !!Created_At && !!Created_By) {
      Modal.info({
        centered: true,
        className: 'modal-info-news',
        title: Title,
        content: (
          <>
            <Row>
              <p style={{ letterSpacing: '0.5px', fontSize: '12px' }}>
                {Description}
              </p>
            </Row>
            {Link_Url ? (
              <Row justify={'start'} style={{ marginTop: '20px' }}>
                <a href={`${Link_Url}`} target='_blank'>
                  <Attachment
                    style={{
                      fontSize: '16px',
                      position: 'relative',
                      top: '4px',
                    }}
                  />{' '}
                  <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                    ดูเอกสารแนบ
                  </span>
                </a>
              </Row>
            ) : (
              ''
            )}
            <Row justify={'end'} style={{ marginTop: Link_Url ? '0' : '20px' }}>
              <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                {Created_By}
              </span>
            </Row>
            <Row justify={'end'}>
              <span style={{ fontSize: '10px' }}>
                ลงวันที่ {getFullDate(Created_At)}
              </span>
            </Row>
          </>
        ),
        okText: 'ปิด',
        onOk() {
          //
        },
        okButtonProps: {
          type: 'ghost',
        },
      })
    }
  }

  const columns = [
    {
      title: 'รายละเอียด',
      key: 'viewDetail',
      align: 'center',
      width: 100,
      render: (news) => (
        <Button
          type='primary'
          shape='circle'
          icon={<EyeOutlined />}
          size={'small'}
          onClick={() => info(news)}
        />
      ),
    },
    {
      title: 'หัวข้อ',
      dataIndex: 'Title',
      key: 'Title',
      align: 'center',
      render: (_, r, i) => {
        if (i === 0) {
          return (
            <>
              <Badge status='error' />
              {_}
            </>
          )
        }
        return <span>{_}</span>
      },
    },
    {
      title: 'ผู้ลงประกาศ',
      dataIndex: 'Created_By',
      key: 'Created_By',
      align: 'center',
    },
    {
      title: 'ประกาศเมื่อ',
      dataIndex: 'Created_At',
      key: 'Created_At',
      align: 'center',
      render: (text) => getFullDate(text),
    },
  ]
  return (
    <Card style={cardStyle} className='stat__card__dashboard'>
      <Row>
        <Typography.Title level={5}>
          <Badge dot>
            <NotificationOutlined style={{ fontSize: 16 }} />
          </Badge>{' '}
          ข่าวประกาศ
        </Typography.Title>
      </Row>
      <Row gutter={[12, 12]} style={{ marginBottom: '15px' }}>
        <Table
          size='small'
          dataSource={data}
          columns={columns}
          style={{ width: '100%' }}
          scroll={{ x: 500 }}
          pagination={false}
          rowKey={'News_ID'}
        />
      </Row>
      <span style={{ fontSize: '12px', color: '#ccc' }}>
        * สามารถเลื่อนตารางไปทางขวาได้
      </span>
    </Card>
  )
}

export default NewsCard
