import React from 'react'
import { Table, Button, Divider, Tag, Modal, Row } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

// helpers
import { getTime, getFullDate } from '../../helpers/DateTime'

const titleStyle = {
  paddingRight: '8px',
  fontWeight: 'bold',
}

const HistoryTable = ({ data, loading }) => {
  const columns = [
    {
      title: 'รายละเอียด',
      key: 'viewDetail',
      align: 'center',
      width: 100,
      render: (stamp) => (
        <Button
          type='primary'
          shape='circle'
          icon={<EyeOutlined />}
          size={'small'}
          onClick={() => showModal(stamp)}
        />
      ),
    },
    {
      title: 'วันที่',
      key: 'DateInOut',
      align: 'center',
      render: (stamp) => (
        <Tag color='default'>{getFullDate(stamp.DateInOut)}</Tag>
      ),
    },
    {
      title: 'เวลา',
      key: 'DateInOut',
      align: 'center',
      render: (stamp) => <Tag color='default'> {getTime(stamp.DateInOut)}</Tag>,
    },
    {
      title: 'สถานะ',
      key: 'DetailT',
      align: 'center',
      render: ({ ID_SInOut, DetailT }) => (
        <>
          {ID_SInOut === 13 ? (
            <Tag color='green'>{DetailT}</Tag>
          ) : ID_SInOut === 12 ? (
            <Tag color='volcano'>{DetailT}</Tag>
          ) : (
            <Tag color='geekblue'>{DetailT}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'สถานที่',
      key: 'location',
      dataIndex: 'GeoAddress',
    },
  ]
  const showModal = ({
    ID_TimeInOut,
    DateInOut,
    DetailT,
    GeoAddress,
    AddMemo,
  }) => {
    Modal.info({
      centered: true,
      className: 'modal-info-stamp',
      title: 'ตรวจสอบข้อมูลลงเวลา',
      content: (
        <div style={{ fontSize: '11px' }}>
          <Divider style={{ margin: '12px 0' }} />
          <Row>
            <span style={titleStyle}>เวลา : </span>
            <span> {getTime(DateInOut)}</span>
          </Row>
          <Row>
            <span style={titleStyle}>วันที่ : </span>
            <span> {getFullDate(DateInOut)}</span>
          </Row>
          <Row>
            <span style={titleStyle}>สถานะ : </span>
            <span> {DetailT}</span>
          </Row>
          <Row>
            <span style={titleStyle}>สถานที่ : </span>
            <span> {GeoAddress}</span>
          </Row>
          <Row>
            <span style={titleStyle}>หมายเหตุ : </span>
            <span> {AddMemo ? AddMemo : '-'}</span>
          </Row>
        </div>
      ),
      okText: 'ปิด',
      onOk() {},
      okButtonProps: {
        type: 'ghost',
      },
    })
  }

  return (
    <>
      <Table
        className='history-table'
        size='small'
        dataSource={data}
        columns={columns}
        style={{ width: '100%' }}
        scroll={{ x: 500 }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
        }}
        rowKey='ID_TimeInOut'
        loading={loading}
      />
      <span style={{ fontSize: '12px', color: '#ccc' }}>
        * สามารถเลื่อนตารางไปทางขวาได้
      </span>
    </>
  )
}

export default HistoryTable
