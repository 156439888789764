import React from 'react'
import { Avatar, Col, Row, Typography, Divider } from 'antd'
import { UserOutlined } from '@ant-design/icons'

// context
import { useAuth } from '../../context/Auth'
import { useResponsive } from '../../context/responsive/Home'

const HeaderTitle = ({ iconColors }) => {
  const { user } = useAuth()
  const { avatarSize, avatarMarginTop, welcomeMessageSize, companyNameSize } =
    useResponsive()

  const rewriteCompanyName = (companyName) => {
    let prefix = companyName.split(' ')[0]
    if (prefix !== 'บริษัท') {
      return `บริษัท ${companyName}`
    }
    return companyName
  }

  return (
    <>
      <Col>
        <Row>
          <Col>
            <Avatar
              size={avatarSize}
              icon={<UserOutlined />}
              style={{
                backgroundColor: iconColors,
              }}
            />
          </Col>
          <Col>
            <Typography.Title
              level={4}
              style={{
                marginTop: avatarMarginTop,
                marginLeft: '10px',
                fontSize: welcomeMessageSize,
              }}>
              ยินดีต้อนรับ, คุณ{`${user.FnameT} ${user.LnameT}`} 👋
            </Typography.Title>
            <span
              style={{
                marginLeft: '10px',
                color: '#ccc',
                fontSize: companyNameSize,
              }}>
              {rewriteCompanyName(user.Company_NameT)}
            </span>
          </Col>
        </Row>
      </Col>
      <Divider style={{ margin: '15px 0' }} />
    </>
  )
}

export default HeaderTitle
