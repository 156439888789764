import React from 'react'
import { Card, Row, Divider, Typography, Table, Button, Tag, Modal } from 'antd'
import { SnippetsOutlined, EyeOutlined } from '@ant-design/icons'

// helpers
import { getFullDate, getTime } from '../../helpers/DateTime'

const RecentStampTable = ({ data, cardStyle }) => {
  const columns = [
    {
      title: 'รายละเอียด',
      key: 'viewDetail',
      align: 'center',
      width: 100,
      render: (stamp) => (
        <Button
          type='primary'
          shape='circle'
          icon={<EyeOutlined />}
          size={'small'}
          onClick={() => showModal(stamp)}
        />
      ),
    },
    {
      title: 'วันที่',
      key: 'DateInOut',
      align: 'center',
      render: (stamp) => (
        <Tag color='default'>{getFullDate(stamp.DateInOut)}</Tag>
      ),
    },
    {
      title: 'เวลา',
      key: 'DateInOut',
      align: 'center',
      render: (stamp) => <Tag color='default'> {getTime(stamp.DateInOut)}</Tag>,
    },
    {
      title: 'สถานะ',
      key: 'DetailT',
      align: 'center',
      render: ({ ID_SInOut, DetailT }) => (
        <>
          {ID_SInOut === 13 ? (
            <Tag color='green'>{DetailT}</Tag>
          ) : ID_SInOut === 12 ? (
            <Tag color='volcano'>{DetailT}</Tag>
          ) : (
            <Tag color='geekblue'>{DetailT}</Tag>
          )}
        </>
      ),
    },
  ]

  const showModal = ({
    ID_TimeInOut,
    DateInOut,
    DetailT,
    GeoAddress,
    AddMemo,
  }) => {
    Modal.info({
      centered: true,
      className: 'modal-info-stamp',
      title: 'ตรวจสอบข้อมูลลงเวลา',
      content: (
        <div style={{ fontSize: '12px' }}>
          <Divider style={{ margin: '12px 0' }} />
          <Row>
            <span style={{ paddingRight: '8px' }}>เวลา : </span>
            <span> {getTime(DateInOut)}</span>
          </Row>
          <Row>
            <span style={{ paddingRight: '8px' }}>วันที่ : </span>
            <span> {getFullDate(DateInOut)}</span>
          </Row>
          <Row>
            <span style={{ paddingRight: '8px' }}>สถานะ : </span>
            <span> {DetailT}</span>
          </Row>
          <Row>
            <span style={{ paddingRight: '8px' }}>สถานที่ : </span>
            <span> {GeoAddress}</span>
          </Row>
          <Row>
            <span style={{ paddingRight: '8px' }}>หมายเหตุ : </span>
            <span> {AddMemo ? AddMemo : '-'}</span>
          </Row>
        </div>
      ),
      okText: 'ปิด',
      onOk() {},
      okButtonProps: {
        type: 'ghost',
      },
    })
  }
  return (
    <>
      <Card style={cardStyle} className='stat__card__dashboard'>
        <Row>
          <Typography.Title level={5}>
            <SnippetsOutlined /> รายการเช็คอินย้อนหลัง{' '}
            <span style={{ fontSize: '10px' }}>( 7 ครั้ง )</span>
          </Typography.Title>
        </Row>
        <Row gutter={[12, 12]} style={{ marginBottom: '15px' }}>
          <Table
            className='recent-stamp-table'
            size='small'
            dataSource={data}
            columns={columns}
            style={{ width: '100%' }}
            scroll={{ x: 500 }}
            pagination={false}
            rowKey={'ID_TimeInOut'}
          />
        </Row>
        <span style={{ fontSize: '12px', color: '#ccc' }}>
          * สามารถเลื่อนตารางไปทางขวาได้
        </span>
      </Card>
    </>
  )
}

export default RecentStampTable
