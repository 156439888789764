import { useState, useEffect } from 'react'
import { Cascader } from 'antd'
import axios from '../../library/Axios'

// notifucation
import { error } from '../Notification'

// context
import { localStorageDecrypt } from '../../library/Storage'

const SelectPeriod = ({ handlePeriodChange }) => {
  const [list, setList] = useState([])
  useEffect(() => {
    const fetchingPeriod = async () => {
      axios
        .get(`/get-period/${localStorageDecrypt('personId')}`, {
          headers: {
            Authorization: `bearer ${localStorageDecrypt('token')}`,
          },
        })
        .then((results) => setList(results.data))
        .catch((err) => error('ไม่พบข้อมูล', 'ส่วนนี้ยังไม่ได้เปิดให้ดู'))
    }
    fetchingPeriod()
    // eslint-disable-next-line
  }, [])
  return (
    <Cascader
      options={list}
      onChange={handlePeriodChange}
      placeholder='กรุณาเลือก ปี / เดือน / งวด'
      style={{ width: '100%' }}
      changeOnSelect
    />
  )
}

export default SelectPeriod
