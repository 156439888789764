import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import Router from '../../routers'
import { useWindowSize } from '../../hooks/windowSize'
import { useSiderContext } from '../../App'
// components
import Headers from './Headers'
import Sidebar from './Sidebar'
import Footers from './Footers'

const { Content } = Layout

function Index() {
  const width = useWindowSize().width
  const { collapsed } = useSiderContext()
  const [hideContent, setHideContent] = useState(false)
  useEffect(() => {
    if (width <= 600 && !collapsed) {
      setHideContent(true)
    } else {
      setHideContent(false)
    }
    // eslint-disable-next-line
  }, [width, collapsed])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <Headers />
        <Content
          style={{
            padding: '10px',
            margin: 0,
          }}>
          <div className='site-layout-background' style={{ minHeight: '100%' }}>
            {hideContent ? '' : <Router />}
          </div>
        </Content>
        <Footers />
      </Layout>
    </Layout>
  )
}

export default Index
