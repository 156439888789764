import React, { useState, createContext, useContext, useEffect } from 'react'
import { useWindowSize } from '../../hooks/windowSize'
export const ResponsiveContext = createContext()

function ResponsiveContextProvider({ children }) {
  // screen width responsive
  const width = useWindowSize().width
  const handleScreenWidth = () => {
    if (width <= 330) {
      // width <= 330
      setAvatarSize(55)
      setAvatarMarginTop('4px')
      setWelcomeMessageSize('16px')
      setCompanyNameSize('14px')
      setColCardSpan(24)
      setOpacityCardIcon('1')
      setColRecentStampTable(24)
      setColGraph(24)
      // ---- end of 330 ----

      // width < 360
    } else if (width > 330 && width <= 494) {
      setAvatarSize(60)
      setAvatarMarginTop('6px')
      setWelcomeMessageSize('18px')
      setCompanyNameSize('14px')
      setColCardSpan(24)
      setOpacityCardIcon('1')
      setColRecentStampTable(24)
      setColGraph(24)
      // ----- end of 360 ------

      // width > 380 && width <= 425
    } else if (width > 494 && width <= 650) {
      setAvatarSize(60)
      setAvatarMarginTop('6px')
      setWelcomeMessageSize('18px')
      setCompanyNameSize('14px')
      setColCardSpan(8)
      setOpacityCardIcon('0.5')
      setColRecentStampTable(24)
      setColGraph(24)
    } else if (width > 650 && width <= 768) {
      setColGraph(24)
      setColRecentStampTable(24)
      setOpacityCardIcon('1')

      // width else
    } else {
      setAvatarSize(70)
      setAvatarMarginTop('8px')
      setWelcomeMessageSize('20px')
      setCompanyNameSize('14px')
      setOpacityCardIcon('1')
      setColCardSpan(8)
      setColRecentStampTable(24)
      setColGraph(8)
    }
  }

  // header page
  const [avatarSize, setAvatarSize] = useState(70)
  const [avatarMarginTop, setAvatarMarginTop] = useState('8px')
  const [welcomeMessageSize, setWelcomeMessageSize] = useState('20px')

  // card stat
  const [companyNameSize, setCompanyNameSize] = useState('14px')
  const [opacityCardIcon, setOpacityCardIcon] = useState('0.5')

  // col span normal item
  const [colCardSpan, setColCardSpan] = useState(8)
  // col span RecentStampTable
  const [colRecentStampTable, setColRecentStampTable] = useState(16)
  // col span graph
  const [colGraph, setColGraph] = useState(8)

  useEffect(() => {
    handleScreenWidth()
    // eslint-disable-next-line
  }, [width])
  const value = {
    avatarSize,
    avatarMarginTop,
    welcomeMessageSize,
    companyNameSize,
    opacityCardIcon,
    colCardSpan,
    colRecentStampTable,
    colGraph,
  }
  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => useContext(ResponsiveContext)
export const ResponsiveConsumer = ResponsiveContext.Consumer
export default ResponsiveContextProvider
