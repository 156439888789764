import React, { useState, useEffect } from 'react'
import { Row, Col, Skeleton } from 'antd'
import axios from '../library/Axios'

// context
import ResponsiveContextProvider, {
  ResponsiveConsumer,
} from '../context/responsive/Home'

// style css
import '../styles/home.css'

// components
import HeaderTitle from '../components/Home/HeaderTitle'
import DailyStampCard from '../components/Home/DailyStampCard'
import MonthlyStampCard from '../components/Home/MonthlyStampCard'
import NewsCard from '../components/Home/NewsCard'
import RecentStampCard from '../components/Home/RecentStampCard'
import RecentStampTable from '../components/Home/RecentStampTable'
import { localStorageDecrypt } from '../library/Storage'

const cardStyle = {
  borderRadius: '10px',
}

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [newsRecord, setNewsRecord] = useState([])
  const [stampThisMonth, setStampThisMonth] = useState(0)
  const [stampTodayCount, setStampTodayCount] = useState(0)
  const [stampRecent, setStampRecent] = useState(null)
  const [recentRecordTable, setRecentRecordTable] = useState([])

  // effect
  useEffect(() => {
    const handleFething = async () => {
      setLoading(true)
      try {
        const id = localStorageDecrypt('personCode')
        const result = await axios.get(`/dashboard/${id}`, {
          headers: {
            Authorization: `bearer ${localStorageDecrypt('token')}`,
          },
        })
        const { news, recentTable, thisMonth, today } = result.data

        setNewsRecord(news)
        setRecentRecordTable(recentTable)
        if (recentTable.length > 0) {
          setStampRecent(recentTable[0].DateInOut)
        }
        setStampThisMonth(thisMonth)
        setStampTodayCount(today)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    handleFething()
    // eslint-disable-next-line
  }, [])

  return (
    <ResponsiveContextProvider>
      <ResponsiveConsumer>
        {({ colCardSpan, colRecentStampTable, colGraph }) => {
          return (
            <div
              style={{
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '8px',
              }}>
              <Row justify={'start'}>
                <HeaderTitle iconColors={'#1890ff'} />
              </Row>

              {loading ? (
                <Skeleton />
              ) : (
                <>
                  <Row
                    gutter={[12, 12]}
                    justify='space-between'
                    style={{ marginBottom: '12px' }}>
                    <Col span={colCardSpan}>
                      <RecentStampCard
                        data={stampRecent}
                        cardStyle={cardStyle}
                        iconColors={'#52c41a'}
                      />
                    </Col>

                    <Col span={colCardSpan}>
                      <DailyStampCard
                        data={stampTodayCount}
                        cardStyle={cardStyle}
                        iconColors={'#13c2c2'}
                      />
                    </Col>

                    <Col span={colCardSpan}>
                      <MonthlyStampCard
                        data={stampThisMonth}
                        cardStyle={cardStyle}
                        iconColors={'#faad14'}
                      />
                    </Col>
                  </Row>

                  <Row
                    gutter={[12, 12]}
                    justify='center'
                    style={{ marginBottom: '12px' }}>
                    <Col span={24}>
                      <NewsCard data={newsRecord} cardStyle={cardStyle} />
                    </Col>
                  </Row>

                  <Row gutter={[12, 12]} justify='space-between'>
                    <Col span={24}>
                      <RecentStampTable
                        data={recentRecordTable}
                        cardStyle={cardStyle}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )
        }}
      </ResponsiveConsumer>
    </ResponsiveContextProvider>
  )
}

export default Home
