import React from 'react'
import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons'

const { Footer } = Layout

const Footers = () => {
  return (
    <Footer
      style={{ textAlign: 'center', fontSize: '10px', letterSpacing: '5px' }}>
      PCT BUSINESS <CopyrightOutlined /> {new Date().getFullYear()}
    </Footer>
  )
}

export default Footers
