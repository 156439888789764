import React, { useState, createContext, useContext, useEffect } from 'react'
import { useWindowSize } from '../../hooks/windowSize'
export const ResponsiveContext = createContext()

function ResponsiveContextProvider({ children }) {
  // screen width responsive
  const width = useWindowSize().width

  const [cardSpan, setCardSpan] = useState(6)
  useEffect(() => {
    const handleScreenWidth = () => {
      if (width <= 330) {
        setCardSpan(24)
        // ---- end of 330 ----
      } else if (width > 330 && width <= 450) {
        setCardSpan(24)
        // ---- end of 360 ----
      } else if (width > 450 && width <= 650) {
        setCardSpan(12)
        // ---- end of 650 ----
      } else if (width > 650 && width < 900) {
        setCardSpan(8)
        // ---- end of 900 ----
      } else {
        setCardSpan(8)
      }
    }
    handleScreenWidth()

    // eslint-disable-next-line
  }, [width])
  const value = {
    cardSpan,
  }
  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => useContext(ResponsiveContext)
export const ResponsiveConsumer = ResponsiveContext.Consumer
export default ResponsiveContextProvider
