import React, { useState, createContext, useContext, useEffect } from 'react'
import { useWindowSize } from '../../hooks/windowSize'
export const ResponsiveContext = createContext()

function ResponsiveContextProvider({ children }) {
  // screen width responsive
  const width = useWindowSize().width
  const handleScreenWidth = () => {
    if (width < 400) {
      setTitleColspan(24)
      setHeaderFontSize('15px')
      setTitleSubHeaderSize('8.5px')
      setTitleFontSize('12px')
    } else if (width >= 400 && width < 500) {
      setTitleColspan(24)
      setHeaderFontSize('17px')
      setTitleSubHeaderSize('8.5px')
      setTitleFontSize('12px')
    } else if (width >= 500 && width < 650) {
      setHeaderFontSize('19px')
      setTitleSubHeaderSize('10px')
      setTitleFontSize('12px')
      setTitleColspan(24)
    } else if (width >= 650 && width < 768) {
      setHeaderFontSize('19px')
      setTitleSubHeaderSize('13px')
      setTitleFontSize('13px')
      setTitleColspan(6)
      // ---- end of 768 ----
    } else {
      setHeaderFontSize('20px')
      setTitleSubHeaderSize('13px')
      setTitleFontSize('13px')
      setTitleColspan(6)
    }
  }

  const [headerFontSize, setHeaderFontSize] = useState('20px')
  const [titleSubHeaderSize, setTitleSubHeaderSize] = useState('13px')
  const [titleFontSize, setTitleFontSize] = useState('13px')
  const [titleColspan, setTitleColspan] = useState(6)
  // eslint-disable-next-line
  const [detailColspan, setDetailColspan] = useState(24 - titleColspan)

  useEffect(() => {
    handleScreenWidth()
    // eslint-disable-next-line
  }, [width])
  const value = {
    headerFontSize,
    titleFontSize,
    titleSubHeaderSize,
    titleColspan,
    detailColspan,
  }
  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => useContext(ResponsiveContext)
export const ResponsiveConsumer = ResponsiveContext.Consumer
export default ResponsiveContextProvider
