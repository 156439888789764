import React from 'react'
import { Row } from 'antd'
// import { BubbleChart } from '@material-ui/icons'
import '../../styles/logo.css'
import logo from '../../assets/login-logo.png'

const Logo = () => {
  return (
    <Row justify={'center'} style={{ paddingBottom: '30px' }}>
      <img width={200} src={logo} alt={''} />
      {/* <Typography.Title level={4}>
        <BubbleChart
          style={{
            fontSize: '60px',
            position: 'relative',
            top: 12,
          }}
        />
        <span className='wording' style={{ fontSize: '42px' }}>
          PCT
        </span>
        <span
          className='wording'
          style={{ fontSize: '9px', letterSpacing: '3px' }}>
          BUSINESS
        </span>
      </Typography.Title> */}
    </Row>
  )
}

export default Logo
