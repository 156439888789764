import React from 'react'
import { Layout } from 'antd'

// context
import { useSiderContext } from '../../App'

// components
import BrandLogo from './BrandLogo'
import MenuItemSidebar from './MenuItemSidebar'

const { Sider } = Layout

const Sidebar = () => {
  const { collapsed } = useSiderContext()
  return (
    <Sider
      collapsed={collapsed}
      collapsedWidth='0'
      style={{ backgroundColor: '#fff' }}>
      <BrandLogo />
      <MenuItemSidebar />
    </Sider>
  )
}

export default Sidebar
