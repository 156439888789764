import React from 'react'
import { Row, Col, Card, Divider, Typography } from 'antd'

// helpers
import {
  leaveBalance,
  leaveFormat,
  leaveMintueToDHM,
} from '../../helpers/LeaveCalculate'

const LeavesCard = ({ data, colSpan }) => {
  let leaveFilters = data.filter(
    (item) =>
      item.leaveDay + item.leaveHours + item.leaveMinutes + item.UsedLeave !== 0
  )

  return (
    <>
      {leaveFilters.length ? (
        leaveFilters.map(
          ({
            ID_Leave,
            Leave_NameT,
            leaveDay,
            leaveHours,
            leaveMinutes,
            UsedLeave,
          }) => (
            <Col span={colSpan} key={`${ID_Leave}`}>
              <Card className='leave-card'>
                <Row>
                  <Typography.Title level={5} style={{ fontSize: '14px' }}>
                    {Leave_NameT}
                  </Typography.Title>
                </Row>
                <Divider style={{ margin: '6px 0 8px 0' }} />
                <Row justify={'space-between'}>
                  <span style={{ fontSize: '12px' }}>สิทธิ์ทั้งหมด </span>
                  <span style={{ fontSize: '12px' }}>
                    {leaveFormat(leaveDay, leaveHours, leaveMinutes)}
                  </span>
                </Row>
                <Row justify={'space-between'}>
                  <span style={{ fontSize: '12px' }}>ใช้ไปแล้ว </span>
                  <span style={{ fontSize: '12px' }}>
                    {leaveMintueToDHM(UsedLeave)}
                  </span>
                </Row>
                <Divider style={{ margin: '6px 0 8px 0' }} />
                <Row justify={'space-between'} style={{ color: '#40a9ff' }}>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    คงเหลือ
                  </span>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {leaveBalance(
                      leaveDay,
                      leaveHours,
                      leaveMinutes,
                      UsedLeave
                    )}
                  </span>
                </Row>
              </Card>
            </Col>
          )
        )
      ) : leaveFilters.length === 0 ? (
        <h4 style={{ paddingLeft: '20px' }}>
          {' '}
          * ไม่พบสิทธิ์การลาในปีนี้...(หากมีข้อสงสัยกรุณาติดต่อฝ่ายบุคคล)
        </h4>
      ) : (
        ''
      )}
    </>
  )
}

export default LeavesCard
