import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Typography, Divider } from 'antd'
import { DateRangeOutlined } from '@material-ui/icons'
import axios from '../library/Axios'

// notification
import { warning } from '../components/Notification'

// styles
import '../styles/history.css'

// components
import HistoryTable from '../components/StampHistory/HistoryTable'
import { localStorageDecrypt } from '../library/Storage'

const iconStyle = { position: 'relative', top: 4, marginRight: '5px' }
const StampHistory = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(() => {
    let unmounted = false
    const fetching = async () => {
      if (!unmounted) {
        setLoading(true)
        axios
          .get(`/time/history/${localStorageDecrypt('personCardId')}`, {
            headers: {
              Authorization: `bearer ${localStorageDecrypt('token')}`,
            },
          })
          .then((results) => setData(results.data))
          .catch((err) => {
            setData([])
            warning(
              'แจ้งเตือน',
              'ไม่พบข้อมูลการลงเวลาในช่วงที่ผ่านมา หากมีข้อมูลสอบถามกรุณาติดต่อฝ่ายบุคคล'
            )
          })
          .finally(setLoading(false))
      }
    }
    fetching()
    return () => {
      unmounted = true
    }
  }, [])
  return (
    <div
      style={{
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '8px',
      }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <Typography.Title
                level={4}
                style={{
                  marginTop: '8px',
                  fontSize: '14px',
                }}>
                <DateRangeOutlined style={iconStyle} />
                ประวัติการลงเวลาทำงาน
              </Typography.Title>
              <span
                style={{
                  color: '#ccc',
                  fontSize: '12px',
                }}>
                ตรวจสอบประวัติการลงเวลาย้อนหลัง
                สามารถเลือกรูปแบบเพื่อขอดูข้อมูลได้
              </span>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: '10px 0' }} />
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card className='history-card' style={{ borderRadius: '8px' }}>
            <HistoryTable data={data} loading={loading} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default StampHistory
