import React from 'react'
import { Col, Row, Card, Typography, Divider } from 'antd'

// helpers
import {
  leaveBalance,
  leaveFormat,
  leaveMintueToDHM,
} from '../../helpers/LeaveCalculate'

const AnnualLeaveCard = ({ data, colSpan }) => {
  const content = () => {
    return (
      <Col span={colSpan}>
        <Card className='leave-card'>
          <Row>
            <Typography.Title level={5} style={{ fontSize: '14px' }}>
              ลาพักร้อน
            </Typography.Title>
          </Row>
          <Divider style={{ margin: '6px 0 8px 0' }} />
          <Row justify={'space-between'}>
            <span style={{ fontSize: '12px' }}>สิทธิ์ทั้งหมด </span>
            <span style={{ fontSize: '12px' }}>
              {leaveFormat(
                data.New_Annal_D,
                data.New_Annal_H,
                data.New_Annal_M
              )}
            </span>
          </Row>
          <Row justify={'space-between'}>
            <span style={{ fontSize: '12px' }}>ใช้ไปแล้ว </span>
            <span style={{ fontSize: '12px' }}>
              {leaveMintueToDHM(data.Used)}
            </span>
          </Row>
          <Divider style={{ margin: '6px 0 8px 0' }} />
          <Row justify={'space-between'} style={{ color: '#40a9ff' }}>
            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
              คงเหลือ
            </span>
            <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
              {leaveBalance(
                data.New_Annal_D,
                data.New_Annal_H,
                data.New_Annal_M,
                data.Used
              )}
            </span>
          </Row>
        </Card>
      </Col>
    )
  }
  return (
    <>
      {data &&
      parseInt(data.New_Annal_D) +
        parseInt(data.New_Annal_H) +
        parseInt(data.New_Annal_M) +
        parseInt(data.Used) !==
        0
        ? content()
        : ''}
    </>
  )
}

export default AnnualLeaveCard
