import React from 'react'
import { Card, Row } from 'antd'

// helpers
import Number from '../../helpers/Number'

const SumYTDCard = ({ title, amount }) => {
  return (
    <Card className='sum_ytd_card'>
      <Row justify='center'>
        <span style={{ fontSize: '12px' }}>{title}</span>
      </Row>
      <Row justify='center'>
        <span style={{ fontSize: '15px' }}> {Number(amount)}</span>
      </Row>
    </Card>
  )
}

export default SumYTDCard
