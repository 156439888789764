import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../../library/Axios'

import { Card, Row, Col, Input, Button, Form } from 'antd'
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  UserOutlined,
  LockOutlined,
  CopyrightOutlined,
} from '@ant-design/icons'

// notification
import { error, warning } from '../../components/Notification'

// hook
import { useWindowSize } from '../../hooks/windowSize'

// login context
import { useAuth } from '../../context/Auth'

// localStorage
import { localStorageEncrypt } from '../../library/Storage'
import Logo from './Logo'

const Login = () => {
  let navigate = useNavigate()
  // auth context
  const { setUser, setLoggedIn } = useAuth()
  const [employeeId, setEmployeeId] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const handleLoginButtonClick = () => {
    if (!employeeId && !password) {
      return warning('แจ้งเตือน', 'กรุณากรอกข้อมูลให้ถูกต้องก่อนกดเข้าสู่ระบบ')
    }
    setLoading(true)
    setTimeout(() => {
      axios
        .post(`/auth/login`, {
          username: employeeId,
          password,
        })
        .then((response) => {
          const { employee, token } = response.data
          const { PersonCode, PersonID, PersonCardID } = employee

          // set data local storage
          localStorageEncrypt('token', token)
          localStorageEncrypt('personCode', PersonCode)
          localStorageEncrypt('personId', PersonID)
          localStorageEncrypt('personCardId', PersonCardID)

          setUser(employee)
          setLoggedIn(true)
          navigate('/')
          setLoading(false)
        })
        .catch((err) => {
          setLoggedIn(false)
          error(
            'ผิดพลาด',
            'รหัสพนักงาน หรือ รหัสผ่านผิดพลาด กรุณาตรวจสอบอีกครั้ง'
          )
          setLoading(false)
        })
    }, 2000)
  }

  // resize login box
  const width = useWindowSize().width
  const [cardWidth, setCardWidth] = useState('300px')
  useEffect(() => {
    const handleWidthChange = () => {
      let w = width - 40
      if (width <= 320) {
        setCardWidth(`${w}px`)
      } else {
        setCardWidth(`${w}px`)
      }
    }
    handleWidthChange()
  }, [width])

  return (
    <Row
      type='flex'
      align='center'
      style={{ backgroundColor: '#F9FAFE', minHeight: '100vh' }}>
      <Col
        style={{
          alignSelf: 'center',
        }}>
        <Card
          className='card_login'
          bordered={false}
          style={{
            width: cardWidth,
            maxWidth: '400px',
            boxShadow: '1px 1px 6px 0px #ccc',
            borderRadius: '25px',
          }}>
          <Logo />
          <Form onFinish={handleLoginButtonClick} autoComplete='off'>
            <Form.Item
              name='employeeId'
              rules={[{ required: true, message: '' }]}>
              <Input
                disabled={loading}
                onChange={(e) => {
                  setEmployeeId(e.target.value)
                }}
                prefix={<UserOutlined />}
                placeholder='รหัสพนักงาน'
                style={{
                  borderRadius: '20px',
                }}
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: ' ',
                },
              ]}>
              <Input.Password
                disabled={loading}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                prefix={<LockOutlined />}
                placeholder='รหัสผ่าน'
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                style={{
                  borderRadius: '20px',
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                htmlType='submit'
                type='primary'
                style={{
                  width: '100%',
                  borderRadius: '20px',
                }}>
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Form>
          <Row justify={'center'} style={{ marginTop: '10px' }}>
            <span
              style={{
                textAlign: 'center',
                fontSize: '8px',
                letterSpacing: '5px',
                opacity: '0.3',
              }}>
              PCT BUSINESS <CopyrightOutlined /> {new Date().getFullYear()}
            </span>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
