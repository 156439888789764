import React from 'react'
import { Row, Card } from 'antd'

// helpers
import Number from '../../helpers/Number'

const SummaryCard = ({ textColor, title, amount, icon }) => {
  return (
    <Card className='summary_card'>
      <Row justify='end'>
        <span>{title}</span>
      </Row>
      <Row style={{ marginTop: '10px', color: textColor }} justify='end'>
        {icon}
        <span style={{ fontSize: '20px' }}> {Number(amount)}</span>
      </Row>
    </Card>
  )
}

export default SummaryCard
