import React from 'react'
import { Card, Row, Col, Divider, Badge } from 'antd'

// helpers
import Number from '../../helpers/Number'

const TransactionCard = ({ title, color, transaction }) => {
  return (
    <Card className='transaction_card'>
      <Row>
        <Col>
          <Badge status={color} />
          {title}
        </Col>
        <Divider style={{ margin: '10px 0' }} />
      </Row>
      {transaction.length > 0
        ? transaction.map(({ key, IncomeNameT, TimeSheet, Amount }) => (
            <Row justify={'space-between'} key={`${key}`}>
              <Col>{IncomeNameT}</Col>
              <Col>{TimeSheet ? TimeSheet : ''}</Col>
              <Col>{Amount ? Number(Amount) : ''}</Col>
            </Row>
          ))
        : ''}
    </Card>
  )
}

export default TransactionCard
