import { useEffect, useState } from 'react'
import { Menu } from 'antd'
import {
  HomeOutlined,
  ClockCircleOutlined,
  BarChartOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'

// context
import { useWindowSize } from '../../hooks/windowSize'
import { useSiderContext } from '../../App'

const MenuItemSidebar = () => {
  const width = useWindowSize().width
  const { setCollapsed } = useSiderContext()
  const location = useLocation()
  const [activeLocation, setActiveLocation] = useState('')
  useEffect(() => {
    const handleChangeLocation = () => {
      setActiveLocation(location.pathname)
      if (width <= 768) {
        setCollapsed((prev) => setCollapsed(true))
      }
    }
    handleChangeLocation()
    // eslint-disable-next-line
  }, [location])

  return (
    <Menu
      theme='light'
      mode='inline'
      selectedKeys={[activeLocation]}
      defaultSelectedKeys={[activeLocation]}>
      <Menu.Item key='/' icon={<HomeOutlined />} style={{ marginBottom: '0' }}>
        <Link to='/'>หน้าแรก</Link>
      </Menu.Item>
      <Menu.Divider style={{ margin: '0' }} />
      <Menu.Item
        key='/stamp'
        icon={<ClockCircleOutlined />}
        style={{ marginBottom: '0', marginTop: '0' }}>
        <Link to={'/stamp'}> ลงเวลาทำงาน</Link>
      </Menu.Item>
      <Menu.Item
        key='/stamp-history'
        icon={<ScheduleOutlined />}
        style={{ marginBottom: '0', marginTop: '0' }}>
        <Link to={'/stamp-history'}> ประวัติการลงเวลา</Link>
      </Menu.Item>
      <Menu.Divider style={{ margin: '0' }} />
      <Menu.Item
        key='/leave'
        icon={<BarChartOutlined />}
        style={{ marginBottom: '0', marginTop: '0' }}>
        <Link to={'/leave'}> สิทธิ์การลา</Link>
      </Menu.Item>
      <Menu.Item
        key='/slip'
        icon={<FileProtectOutlined />}
        style={{ marginBottom: '0', marginTop: '0' }}>
        <Link to={'/slip'}>สลิปเงินเดือน</Link>
      </Menu.Item>
    </Menu>
  )
}

export default MenuItemSidebar
