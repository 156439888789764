const IdentityIdFormat = (id) => {
  /* 0 - 0000 - 00000 - 00 - 0 */
  let a = id.slice(0, 1)
  let b = id.slice(1, 5)
  let c = id.slice(5, 10)
  let d = id.slice(10, 12)
  let e = id.slice(12, 13)

  return `${a}-${b}-${c}-${d}-${e}`
}

const BankAccountFormat = (id) => {
  // 413 - 1 - 00127 - 6
  console.log('bank no : ', id)
  let a = id.slice(0, 3)
  let b = id.slice(3, 4)
  let c = id.slice(4, 8)
  let d = id.slice(8, 10)
  return `${a}-${b}-${c}-${d}`
}

module.exports = {
  IdentityIdFormat,
  BankAccountFormat,
}
