import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../library/Axios'
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Form,
  Select,
  Input,
  Button,
  Skeleton,
} from 'antd'
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons'
import '../styles/stamp.css'
import { FingerprintOutlined } from '@material-ui/icons'

import { success, error, warning } from '../components/Notification'

// fontAwesome icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'

// longdo
import { longdo, map, LongdoMap, mapKey } from '../library/LongdoMap'

// context
import ResponsiveContextProvider, {
  ResponsiveConsumer,
} from '../context/responsive/Stamp'
import { useAuth } from '../context/Auth'

// hooks
import { useContainerDimensions } from '../hooks/dimensions'
import { useWindowSize } from '../hooks/windowSize'

// helpers
import { IdentityIdFormat } from '../helpers/UserData'
import { localStorageDecrypt } from '../library/Storage'

const Stamp = () => {
  const [waitingLoad, setWaitingLoad] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setWaitingLoad(false)
    }, 5000)
  }, [])

  const screenWidth = useWindowSize().width
  const componentRefs = useRef()
  const { height } = useContainerDimensions(componentRefs)
  const { user } = useAuth()

  const [cardHeight, setCardHeight] = useState(height)
  useEffect(() => {
    const handleFormResize = () => {
      if (screenWidth <= 550) {
        setCardHeight(200)
      } else {
        setCardHeight(height - 4)
      }
    }
    handleFormResize()
  }, [height, screenWidth])

  const [loadsuccess, setLoadsuccess] = useState(0)
  const [loading, setLoading] = useState(false)
  const [geoLocation, setGeoLocation] = useState({ lat: 0, lon: 0 })
  const initMap = async () => {
    setLoading(true)
    map.location(longdo.LocationMode.Geolocation)
    map.zoom(15)
    const { Ui } = map
    Ui.DPad.visible(false)
    Ui.Mouse.enable(false)
    Ui.Keyboard.enable(false)
    Ui.Zoombar.visible(false)
    // Ui.Geolocation.visible(true)
    Ui.Toolbar.visible(false)
    Ui.LayerSelector.visible(false)
    Ui.Fullscreen.visible(false)
    Ui.Scale.visible(false)

    map.Event.bind('ready', () => {
      const loc = map.location()
      setGeoLocation(loc)
      setLoadsuccess((prev) => prev + 1)
    })

    map.Event.bind('location', () => {
      const loc = map.location()
      setGeoLocation(loc)
      setLoadsuccess((prev) => prev + 1)
    })
    setLoading(false)
  }

  useEffect(() => {
    const handleGeoLocation = async () => {
      if (geoLocation.lat && geoLocation.lon) {
        const { lat, lon } = geoLocation
        fetch(
          `https://api.longdo.com/map/services/address?lon=${lon}&lat=${lat}&key=${mapKey}`
        )
          .then((response) => response.json())
          .then((data) => {
            const { road, subdistrict, district, province, postcode } = data
            let addr = ''
            addr += road ? road + ' ' : ''
            addr += subdistrict ? subdistrict + ' ' : ''
            addr += district ? district + ' ' : ''
            addr += province ? province + ' ' : ''
            addr += postcode ? postcode : ''
            setCurrentAddress(addr)
            setCoordinate(geoLocation)
          })
      }
    }
    handleGeoLocation()
    return () => {
      //
    }
    // eslint-disable-next-line
  }, [loadsuccess])

  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    const getStatusList = async () => {
      const results = await axiosInstance.get(`/time/status`, {
        headers: {
          Authorization: `bearer ${localStorageDecrypt('token')}`,
        },
      })
      setStatusList(results.data)
    }
    getStatusList()
  }, [])

  // state for submit
  const [coordinate, setCoordinate] = useState('')
  const [statusId, setStatusId] = useState(null)
  const [currentAddress, setCurrentAddress] = useState('')
  const [addMemo, setAddMemo] = useState('')
  const [submiting, setSubmiting] = useState(false)
  const handleSubmitStamp = async () => {
    if (currentAddress.length <= 0) {
      return error(
        'ผิดพลาด',
        `ไม่พบตำแหน่งของคุณบนแผนที่ กรุณา 'อนุญาติ' ให้เข้าถึงตำแหน่งที่ตั้งของอุปกรณ์และลองใหม่ในภายหลัง`
      )
    }
    if (!statusId) {
      return warning('คำเตือน', `กรุณาเลือก 'สถานะการลงเวลา' ก่อนกดลงเวลา`)
    }

    setSubmiting(true)
    const result = await axiosInstance.post(
      `/time/stamp`,
      {
        cardId: `${localStorageDecrypt('personCardId')}`,
        ID_SInOut: statusId,
        AddMemo: addMemo,
        Coordinate: `${coordinate.lat}, ${coordinate.lon}`,
        GeoAddress: currentAddress,
      },
      {
        headers: {
          Authorization: `bearer ${localStorageDecrypt('token')}`,
        },
      }
    )

    setTimeout(() => {
      if (result.status === 201) {
        success('ลงเวลาสำเร็จ', ``)
        setSubmiting(false)
        return clearState()
      } else {
        setSubmiting(false)
        error(
          'พบข้อผิดพลาด',
          'กรุณาลองใหม่อีกครั้ง หรือออกจากระบบแล้วกลับมาในภายหลัง.'
        )
      }
    }, 2000)
  }

  const clearState = () => {
    setStatusId(null)
    setAddMemo('')
    window.scrollTo(0, 0)
  }

  return (
    <ResponsiveContextProvider>
      <ResponsiveConsumer>
        {({
          avatarSize,
          avatarMarginTop,
          headerMessage,
          subHeaderMessage,
          colSpan,
          formLabel,
          formCol,
          offsetButton,
          cardSpan,
        }) => {
          const layout = {
            labelCol: {
              span: formLabel,
            },
            wrapperCol: {
              span: formCol,
            },
          }

          return (
            <div
              style={{
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '8px',
              }}>
              <Row justify={'start'}>
                <Col>
                  <Row>
                    <Col>
                      <Typography.Title
                        level={4}
                        style={{
                          marginTop: '8px',
                          marginLeft: '5px',
                          fontSize: headerMessage,
                        }}>
                        <ClockCircleOutlined /> ลงเวลาทำงาน
                      </Typography.Title>
                      <span
                        style={{
                          marginLeft: '5px',
                          color: '#ccc',
                          fontSize: subHeaderMessage,
                        }}>
                        ใช้สำหรับการลงเวลาเข้าออกงาน
                        กรุณาอนุญาติให้เข้าถึงตำแหน่งของอุปกรณ์
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Divider style={{ margin: '10px 0' }} />
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={cardSpan}>
                  <Card
                    style={{ borderRadius: '8px' }}
                    className='stat__card__dashboard'>
                    <Row>
                      <Typography.Title level={5}>
                        <FontAwesomeIcon icon={faMapMarkedAlt} /> แผนที่
                      </Typography.Title>
                    </Row>
                    <Divider style={{ margin: '10px 0 15px 0' }} />
                    <Row style={{ height: cardHeight }}>
                      <LongdoMap id='longdo-map-stamp' callback={initMap} />
                    </Row>
                  </Card>
                </Col>
                <Col span={cardSpan}>
                  <Card
                    style={{ borderRadius: '8px' }}
                    className='stat__card__dashboard'>
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <>
                        <Row>
                          <Typography.Title level={5}>
                            <UserOutlined /> ข้อมูล
                          </Typography.Title>
                        </Row>
                        <Divider style={{ margin: '10px 0' }} />
                        <Row ref={componentRefs}>
                          <>
                            <Form
                              className='stamp-form'
                              {...layout}
                              name='nest-messages'
                              style={{ width: '100%' }}>
                              <Form.Item label='รหัส/ชื่อ'>
                                <Input
                                  value={`${user.PersonCode} - ${user.FnameT} ${user.LnameT}`}
                                  disabled
                                />
                              </Form.Item>

                              <Form.Item label='บัตรประชาชน'>
                                <Input
                                  value={IdentityIdFormat(user.IdentityID)}
                                  disabled
                                  style={{ letterSpacing: '1px' }}
                                />
                              </Form.Item>

                              <Form.Item label='สถานที่'>
                                <Input.TextArea
                                  value={
                                    waitingLoad
                                      ? 'กำลังโหลดข้อมูลแผนที่....'
                                      : currentAddress
                                  }
                                  disabled
                                />
                              </Form.Item>

                              <Form.Item label='สถานะ'>
                                <Select
                                  loading={waitingLoad || submiting}
                                  placeholder='เลือกสถานะ'
                                  value={statusId}
                                  disabled={waitingLoad}
                                  onChange={(val) => setStatusId(val)}>
                                  {statusList.length
                                    ? statusList.map(
                                        ({ ID_SInout, DetailT }) => (
                                          <Select.Option
                                            key={`${ID_SInout}`}
                                            value={ID_SInout}>
                                            {DetailT}
                                          </Select.Option>
                                        )
                                      )
                                    : ''}
                                </Select>
                              </Form.Item>

                              <Form.Item label='หมายเหตุ'>
                                <Input.TextArea
                                  placeholder='ระบุหรือไม่ก็ได้'
                                  value={addMemo}
                                  onChange={(e) => setAddMemo(e.target.value)}
                                  disabled={waitingLoad || submiting}
                                />
                              </Form.Item>

                              <Form.Item
                                wrapperCol={{
                                  ...layout.wrapperCol,
                                  offset: offsetButton,
                                }}>
                                <Button
                                  loading={waitingLoad || submiting}
                                  type='primary'
                                  htmlType='submit'
                                  style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                  }}
                                  onClick={() => handleSubmitStamp()}>
                                  {waitingLoad ? (
                                    'กำลังค้นหาตำแหน่ง...'
                                  ) : submiting ? (
                                    ''
                                  ) : (
                                    <Row justify={'center'}>
                                      <FingerprintOutlined />
                                      <span style={{ paddingLeft: '5px' }}>
                                        ลงเวลา
                                      </span>
                                    </Row>
                                  )}
                                </Button>
                              </Form.Item>
                            </Form>
                          </>
                        </Row>
                      </>
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
          )
        }}
      </ResponsiveConsumer>
    </ResponsiveContextProvider>
  )
}

export default Stamp
