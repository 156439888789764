function getDate(d) {
  // 2021-10-05T13:50:13.000Z
  const date = new Date(d).toISOString()
  const subDate = date.split('T')[0]
  const dateArr = subDate.split('-')
  const day = dateArr[2]
  const month = dateArr[1]
  const year = dateArr[0]
  return `${day}/${month}/${year}`
}

function getTime(d) {
  const date = new Date(d).toISOString()
  const subTime = date.split('T')[1]
  return subTime.substring(0, 5)
}

function getFullDate(d) {
  const date = new Date(d).toISOString()
  const subDate = date.split('T')[0]
  const dateArr = subDate.split('-')
  const day = dateArr[2]
  const month = monthList(dateArr[1])
  const year = parseInt(dateArr[0]) + 543
  return `${day} ${month} ${year}`
}

function getPeriodString(d, p) {
  const date = new Date(d).toISOString()
  const subDate = date.split('T')[0]
  const dateArr = subDate.split('-')
  const month = monthList(dateArr[1])
  const year = parseInt(dateArr[0]) + 543
  return `${year} ${month} งวดที่ ${parseInt(p)}`
}

const getUTCTime = (val) => {
  let h = new Date(val).getUTCHours()
  if (h.toString().length === 1) {
    h = '0' + h
  }

  let m = new Date(val).getUTCMinutes()
  if (m.toString().length === 1) {
    m = '0' + m
  }
  return `${h}:${m}`
}

function monthList(n) {
  switch (n) {
    case '01':
      return 'มกราคม'

    case '02':
      return 'กุมภาพันธ์'

    case '03':
      return 'มีนาคม'

    case '04':
      return 'เมษายน'

    case '05':
      return 'พฤษภาคม'

    case '06':
      return 'มิถุนายน'

    case '07':
      return 'กรกฎาคม'

    case '08':
      return 'สิงหาคม'

    case '09':
      return 'กันยายน'

    case '10':
      return 'ตุลาคม'

    case '11':
      return 'พฤศจิกายน'

    case '12':
      return 'ธันวาคม'

    default:
      return ''
  }
}

module.exports = {
  getDate,
  getTime,
  getFullDate,
  getPeriodString,
  getUTCTime,
}
