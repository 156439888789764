import React, { useState } from 'react'
import { Row, Col, Card, Typography, Divider, Input, Button } from 'antd'
import { AccountTreeOutlined, LockOpen, LockOutlined } from '@material-ui/icons'
import axios from '../../library/Axios'

// components
import { error, success, warning } from '../../components/Notification'

// style
import '../../styles/changePassword.css'

// context
import { useAuth } from '../../context/Auth'
import { localStorageDecrypt } from '../../library/Storage'

const titleColspan = 24
const detailColspan = 24
const iconStyle = {
  position: 'relative',
  top: 4,
  marginRight: '5px',
}

const iconPasswordStyle = {
  position: 'relative',
  top: 4,
  marginRight: '5px',
  fontSize: '17px',
}
const titleStyle = { paddingTop: '2px', fontSize: ' 11px' }

const ChangePassword = () => {
  const { user, setLogout } = useAuth()

  // state for password change
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const handleSubmit = () => {
    if (!!user.Pws && !!oldPassword && !!newPassword && !!confirmNewPassword) {
      // old password does not matched
      if (user.Pws !== oldPassword) {
        return error(
          'ผิดพลาด',
          'รหัสผ่านเดิมไม่ตรงกัน กรุณาตรวจสอบรหัสผ่านอีกครั้ง'
        )
      }

      // new password does not matched
      if (newPassword !== confirmNewPassword) {
        return error(
          'ผิดพลาด',
          'รหัสผ่านที่คุณเลือกไม่ตรงกัน กรุณาตรวจสอบรหัสผ่านอีกครั้ง'
        )
      }

      if (newPassword.length <= 3) {
        return warning(
          'แจ้งเตือน',
          'รหัสผ่านจะต้องมีความยาวมากกว่า 4 ตัวอักษรขึ้นไป'
        )
      }

      const id = localStorageDecrypt('personId')
      axios
        .put(
          `/auth/change-password/${id}`,
          { password: newPassword },
          {
            headers: {
              Authorization: `bearer ${localStorageDecrypt('token')}`,
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            success(
              'บันทึกสำเร็จ',
              'รหัสผ่านแล้วของคุณได้ถูกเปลี่ยนแล้ว! กรุณาเข้่าสู่ระบบใหม่อีกครั้ง'
            )
            return setLogout()
          } else {
            return error(
              'พบข้อผิดพลาดบางอย่าง',
              'กรุณาออกจากระบบและเปลี่ยนรหัสผ่านอีกครั้งภายหลัง'
            )
          }
        })
        .catch((err) => {
          console.log(err)
          return error('Error!', err.message)
        })
      //
    } else {
      return error('ผิดพลาด', 'กรุณากรอกข้อมูลให้ครบทุกช่อง')
    }
  }
  return (
    <div
      style={{
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '8px',
      }}>
      <Row>
        <Col>
          <Row>
            <Col>
              <Typography.Title
                level={4}
                style={{
                  marginTop: '8px',
                  fontSize: '14px',
                }}>
                <AccountTreeOutlined style={iconStyle} />
                จัดการรหัสผ่าน
              </Typography.Title>
              <span
                style={{
                  color: '#ccc',
                  fontSize: '10px',
                }}>
                สามารถเปลี่ยนรหัสผ่านได้ทุกเมื่อตามต้องการ หาก "ลืมรหัสผ่าน"
                ต้องแจ้งฝ่ายบุคคลเพื่อขอรหัสใหม่อีกครั้ง
              </span>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: '10px 0' }} />
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card className='password-card' style={{ borderRadius: '8px' }}>
            <Row justify='space-between'>
              <Col span={titleColspan}>
                <Typography.Title level={5} style={titleStyle}>
                  <LockOpen style={iconPasswordStyle} />
                  รหัสผ่านเดิม
                </Typography.Title>
              </Col>
              <Col span={detailColspan}>
                <Input.Password
                  placeholder='รหัสผ่านเดิม'
                  type='password'
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row justify='space-between' style={{ margin: '25px 0' }}>
              <Col span={titleColspan}>
                <Typography.Title level={5} style={titleStyle}>
                  <LockOutlined style={iconPasswordStyle} />
                  รหัสผ่านใหม่
                </Typography.Title>
              </Col>
              <Col span={detailColspan}>
                <Input.Password
                  placeholder='รหัสผ่านใหม่'
                  type='password'
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={titleColspan}>
                <Typography.Title level={5} style={titleStyle}>
                  <LockOutlined style={iconPasswordStyle} />
                  ยืนยันรหัสผ่านใหม่
                </Typography.Title>
              </Col>
              <Col span={detailColspan}>
                <Input.Password
                  placeholder='ยืนยันรหัสผ่านใหม่'
                  type='password'
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: '30px' }}>
              <Col span={24}>
                <Button
                  type='primary'
                  style={{ width: '100%', borderRadius: '8px' }}
                  onClick={handleSubmit}>
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ChangePassword
