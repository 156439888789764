import React, { useRef } from 'react'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { Button, Row, Col } from 'antd'

import ReactToPrint from 'react-to-print'

// style slip
import '../../styles/FormSlip.css'

// context
import { AuthConsumer } from '../../context/Auth'
import { SlipConsumer } from '../../pages/Slip'

// helpers
import number from '../../helpers/Number'
import { getFullDate } from '../../helpers/DateTime'

const fontSlip = '10px'
const PrintComponent = () => {
  const container = useRef(null)
  return (
    <>
      <ReactToPrint
        content={() => container.current}
        trigger={() => (
          <Button type='primary' style={{ width: '100%', borderRadius: '8px' }}>
            <CloudDownloadOutlined style={{ fontSize: '15px' }} />
            บันทึกสลิป
          </Button>
        )}
      />
      <div style={{ display: 'none' }}>
        <AuthConsumer>
          {({ user }) => {
            return (
              <div
                className='root-form-slip'
                key={'slip-auth-main'}
                ref={container}>
                <SlipConsumer>
                  {({
                    income,
                    deduct,
                    grandTotal,
                    incomeSummary,
                    deductSummary,
                    YTDIncome,
                    YTDTax,
                    YTDSSF,
                    payDate,
                    periodString,
                    startOT,
                    endOT,
                  }) => {
                    return (
                      <div
                        className='slip-container'
                        key={'slip-container-main'}>
                        <Row justify={'end'}>
                          <Col>
                            <p style={{ fontSize: fontSlip, color: 'grey' }}>
                              วันที่พิมพ์ : {getFullDate(Date.now())}
                            </p>
                          </Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col>
                            <h3 style={{ fontSize: '15px' }}>
                              {user.Company_NameE}
                            </h3>
                          </Col>
                          <Col></Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col span={12}>
                            <p style={{ fontSize: fontSlip }}>
                              ใบจ่ายเงินเดือน
                            </p>
                          </Col>
                          <Col>
                            <p style={{ fontSize: fontSlip }}>
                              ปี/เดือน/งวด : {periodString}
                            </p>
                          </Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col span={12}>
                            <p style={{ fontSize: fontSlip }}>
                              รหัสพนักงาน : {user.PersonCode}
                            </p>
                          </Col>
                          <Col>
                            <p style={{ fontSize: fontSlip }}>
                              ประเภทพนักงาน : {user.EmpTypeNameT}
                            </p>
                          </Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col span={12}>
                            <p style={{ fontSize: fontSlip }}>
                              ชื่อ-สกุล : {`${user.FnameT} ${user.LnameT}`}
                            </p>
                          </Col>
                          <Col>
                            <p style={{ fontSize: fontSlip }}>
                              ธนาคาร : {user.BankNameT}
                            </p>
                          </Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col span={12}>
                            <p style={{ fontSize: fontSlip }}>
                              แผนก : {user.Cmb2NameT}
                            </p>
                          </Col>
                          <Col>
                            <p style={{ fontSize: fontSlip }}>
                              เลขที่บัญชี : {user.AccountNO}
                            </p>
                          </Col>
                        </Row>
                        <Row justify={'start'}>
                          <Col span={12}>
                            <p style={{ fontSize: fontSlip }}>
                              ตำแหน่ง : {user.PositionNameT}
                            </p>
                          </Col>
                          <Col>
                            <SlipConsumer>
                              {({ payDate }) => (
                                <p
                                  style={{ fontSize: fontSlip }}
                                  key={'pay-date'}>
                                  วันที่จ่าย : {payDate}
                                </p>
                              )}
                            </SlipConsumer>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}></Col>
                          <Col span={12}>
                            <p style={{ fontSize: fontSlip }}>
                              รอบวันทำงาน :
                              {` ${getFullDate(endOT)} ถึง ${getFullDate(
                                startOT
                              )}`}
                            </p>
                          </Col>
                        </Row>
                        <div className='watermark'>
                          <div className='table-main'>
                            <Row
                              className='row-border'
                              justify='space-around'
                              style={{
                                backgroundColor: '#c9c9c9',
                              }}>
                              <Col
                                span={12}
                                style={{
                                  borderRight: '1px solid #ccc',
                                  textAlign: 'center',
                                  fontWeight: '500',
                                  fontSize: fontSlip,
                                }}>
                                รายการได้
                              </Col>
                              <Col
                                span={12}
                                style={{
                                  textAlign: 'center',
                                  fontSize: fontSlip,
                                }}>
                                รายการหัก
                              </Col>
                            </Row>
                            <div className='table-content'>
                              <Row style={{ minHeight: '100%' }}>
                                <div
                                  className='vertical-transaction'
                                  style={{
                                    borderRight: '1px solid #ccc',
                                  }}>
                                  {income.map(
                                    ({ IncomeNameT, TimeSheet, Amount }) => (
                                      <Row
                                        justify='space-between'
                                        style={{ padding: '0 12px' }}>
                                        <Col
                                          span={6}
                                          style={{ fontSize: fontSlip }}>
                                          {IncomeNameT}
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            fontSize: fontSlip,
                                            textAlign: 'center',
                                          }}>
                                          {TimeSheet ? TimeSheet : ''}
                                        </Col>
                                        <Col
                                          span={6}
                                          style={{
                                            fontSize: fontSlip,
                                            textAlign: 'right',
                                          }}>
                                          {number(Amount)}
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </div>
                                <div className='vertical-transaction'>
                                  {deduct.map(
                                    ({ IncomeNameT, TimeSheet, Amount }) => (
                                      <Row
                                        justify='space-between'
                                        style={{ padding: '0 12px' }}>
                                        <Col
                                          span={6}
                                          style={{ fontSize: fontSlip }}>
                                          {IncomeNameT}
                                        </Col>
                                        <Col
                                          span={12}
                                          style={{
                                            fontSize: fontSlip,
                                            textAlign: 'center',
                                          }}>
                                          {TimeSheet ? TimeSheet : ''}
                                        </Col>
                                        <Col
                                          span={6}
                                          style={{
                                            fontSize: fontSlip,
                                            textAlign: 'right',
                                          }}>
                                          {number(Amount)}
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </div>
                              </Row>
                            </div>
                            <Row
                              className='row-border'
                              justify='space-between'
                              style={{ backgroundColor: '#c9c9c9' }}>
                              <Col
                                span={8}
                                style={{
                                  paddingLeft: '15px',
                                  fontSize: fontSlip,
                                }}>
                                รวมรายได้
                              </Col>
                              <Col
                                span={4}
                                style={{
                                  textAlign: 'end',
                                  paddingRight: '10px',
                                  fontSize: fontSlip,
                                }}>
                                {number(incomeSummary)}
                              </Col>
                              <Col
                                span={8}
                                style={{
                                  paddingLeft: '15px',
                                  borderLeft: '1px solid #ccc',
                                  fontSize: fontSlip,
                                }}>
                                รวมรายหัก
                              </Col>
                              <Col
                                span={4}
                                style={{
                                  textAlign: 'end',
                                  paddingRight: '10px',
                                  fontSize: fontSlip,
                                }}>
                                {number(deductSummary)}
                              </Col>
                            </Row>
                            <Row
                              justify={'space-around'}
                              style={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                border: '1px solid #ccc',
                                borderTop: '0',
                                fontSize: fontSlip,
                              }}>
                              <Col style={{ textAlign: 'center' }}>
                                <span>รายได้สะสม</span>
                                <p style={{ textAlign: 'center' }}>
                                  {number(YTDIncome)}
                                </p>
                              </Col>
                              <Col style={{ textAlign: 'center' }}>
                                <span>ภาษีสะสม</span>
                                <p style={{ textAlign: 'center' }}>
                                  {number(YTDTax)}
                                </p>
                              </Col>
                              <Col style={{ textAlign: 'center' }}>
                                <span>ประกันสังคมสะสม</span>
                                <p style={{ textAlign: 'center' }}>
                                  {number(YTDSSF)}
                                </p>
                              </Col>
                              <Col style={{ textAlign: 'center' }}>
                                <span>รายได้สุทธิ</span>
                                <p style={{ textAlign: 'center' }}>
                                  {number(grandTotal)}
                                </p>
                              </Col>
                            </Row>
                            <p className='electronic-message'>
                              ใบแจ้งเงินเดือนถูกพิมพ์โดยระบบอิเล็กทรอนิกส์
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </SlipConsumer>
              </div>
            )
          }}
        </AuthConsumer>
      </div>
    </>
  )
}

export default PrintComponent
