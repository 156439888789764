import React from 'react'
import { Typography } from 'antd'
// import { BubbleChart } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import '../../styles/logo.css'

const BrandLogo = () => {
  return (
    <Link to='/'>
      <div className='logo' style={{ height: '46px', padding: 0 }}>
        <Typography.Title level={2} style={{ padding: '3px 0 0 22px' }}>
          {/* <BubbleChart
            style={{ fontSize: '35px', position: 'relative', top: 7, left: 0 }}
          /> */}
          <span
            className='wording'
            style={{
              letterSpacing: '1px',
              fontSize: '27px',
              fontWeight: 'bold',
            }}>
            PCT
          </span>{' '}
          <span
            className='wording'
            style={{
              fontSize: '8px',
              letterSpacing: '6px',
            }}>
            BUSINESS
          </span>
        </Typography.Title>
      </div>
    </Link>
  )
}

export default BrandLogo
