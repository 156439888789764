import React, { useState, createContext, useContext, useEffect } from 'react'
import { useWindowSize } from '../../hooks/windowSize'
export const ResponsiveContext = createContext()

function ResponsiveContextProvider({ children }) {
  // screen width responsive
  const width = useWindowSize().width
  const handleScreenWidth = () => {
    if (width <= 330) {
      // width <= 330
      setHeaderMessage('16px')
      setSubHeaderMessage('9px')
      setColSpan(24)
      setOffsetButton(0)
      setCardSpan(24)
      // ---- end of 330 ----
    } else if (width > 330 && width <= 425) {
      setHeaderMessage('18px')
      setSubHeaderMessage('9px')
      setCardSpan(24)

      if (width > 348) {
        setSubHeaderMessage('10px')
      }

      if (width > 378) {
        setSubHeaderMessage('11px')
      }
      setColSpan(24)
      setOffsetButton(0)
      // ----- end of 360 ------
    } else if (width > 425 && width <= 650) {
      setHeaderMessage('18px')
      setSubHeaderMessage('11px')
      setColSpan(24)
      setOffsetButton(0)
      setCardSpan(24)

      if (width >= 576) {
        setFormLabel(6)
        setFormCol(18)
        setOffsetButton(6)
      }
    } else if (width > 650 && width < 900) {
      setHeaderMessage('19px')
      setSubHeaderMessage('12px')
      setFormLabel(5)
      setFormCol(19)
      setOffsetButton(5)
      setCardSpan(24)
      // ---- end of 1000 ----
    } else {
      setHeaderMessage('20px')
      setSubHeaderMessage('13px')
      setColSpan(12)
      setFormLabel(6)
      setFormCol(18)
      setOffsetButton(6)
      setCardSpan(12)
    }
  }

  // header page
  const [headerMessage, setHeaderMessage] = useState('20px')
  const [subHeaderMessage, setSubHeaderMessage] = useState('8px')

  // col span
  const [colSpan, setColSpan] = useState(12)

  // card span
  const [cardSpan, setCardSpan] = useState(12)

  // form
  const [formLabel, setFormLabel] = useState(8)
  const [formCol, setFormCol] = useState(16)
  const [offsetButton, setOffsetButton] = useState(8)

  useEffect(() => {
    handleScreenWidth()
    // eslint-disable-next-line
  }, [width])
  const value = {
    headerMessage,
    subHeaderMessage,
    colSpan,
    formLabel,
    formCol,
    offsetButton,
    cardSpan,
  }
  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  )
}

export const useResponsive = () => useContext(ResponsiveContext)
export const ResponsiveConsumer = ResponsiveContext.Consumer
export default ResponsiveContextProvider
