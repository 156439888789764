const leaveFormat = (d, h, m) => {
  if (parseInt(d) + parseInt(h) + parseInt(m) === 0) return `-`
  let results = ``
  if (d !== 0) {
    results += `${d} วัน `
  }

  if (h !== 0) {
    results += `${h} ชั่วโมง `

    if (m !== 0) {
      results += `${m} นาที`
    }
  }

  return results
}

const leaveMintueToDHM = (used) => {
  let day = used / 480
  let hours = (day % 1) * 8
  let mintues = (hours % 1) * 60

  if (used === 0) return `-`
  let results = ``
  if (parseInt(day) !== 0) {
    results += `${parseInt(day)} วัน `
  }

  if (parseInt(hours) !== 0) {
    results += `${parseInt(hours)} ชั่วโมง `

    if (parseInt(mintues) !== 0) {
      results += `${parseInt(mintues)} นาที`
    }
  }
  return results
}

const leaveBalance = (d, h, m, used) => {
  let day = d * 8 * 60
  let hours = h * 60
  let leaveAll = day + hours + m
  let results = leaveAll - used
  return leaveMintueToDHM(results)
}

module.exports = {
  leaveFormat,
  leaveBalance,
  leaveMintueToDHM,
}
