import React, { useEffect, useState } from 'react'
import { Row, Col, Divider, Typography, Skeleton } from 'antd'
import { localStorageDecrypt } from '../library/Storage'

// styles
import '../styles/leave.css'

// context
import ResponsiveContextProvider, {
  ResponsiveConsumer,
} from '../context/responsive/Leave'

// fontAwesome icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import AnnualLeaveCard from '../components/Leave/AnnualLeaveCard'
import LeavesCard from '../components/Leave/LeavesCard'
import axios from '../library/Axios'

const Leave = () => {
  const [loading, setLoading] = useState(false)
  const [holiday, setHoliday] = useState(null)
  const [other, setOther] = useState([])
  useEffect(() => {
    let unmounted = false
    const fetch = async () => {
      setLoading(true)
      if (!unmounted) {
        const results = await axios.get(
          `/leave/get/${localStorageDecrypt('personId')}`,
          {
            headers: {
              Authorization: `bearer ${localStorageDecrypt('token')}`,
            },
          }
        )
        const { holidayLeave, otherLeave } = results.data
        setHoliday(holidayLeave)
        setOther(otherLeave)
        setLoading(false)
      }
    }
    fetch()
    return () => {
      unmounted = true
    }
  }, [])
  return (
    <ResponsiveContextProvider>
      <ResponsiveConsumer>
        {({ cardSpan }) => {
          return (
            <div
              style={{
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '8px',
              }}>
              <Row justify={'start'}>
                <Col>
                  <Row>
                    <Col>
                      <Typography.Title
                        level={4}
                        style={{
                          marginTop: '8px',
                          marginLeft: '5px',
                        }}>
                        <FontAwesomeIcon icon={faChartBar} /> สิทธิ์การลา
                      </Typography.Title>
                      <span
                        style={{
                          marginLeft: '5px',
                          color: '#ccc',
                        }}>
                        ตรวจสอบสิทธิ์การลาคงเหลือ/ใช้ไปทั้งหมด
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Divider style={{ margin: '10px 0' }} />
              </Row>
              {loading ? (
                <Skeleton />
              ) : (
                <Row gutter={[12, 12]} justify={'start'}>
                  {holiday ? (
                    <AnnualLeaveCard data={holiday} colSpan={cardSpan} />
                  ) : (
                    ''
                  )}
                  {other.length > 0 ? (
                    <LeavesCard data={other} colSpan={cardSpan} />
                  ) : (
                    ''
                  )}
                </Row>
              )}
            </div>
          )
        }}
      </ResponsiveConsumer>
    </ResponsiveContextProvider>
  )
}

export default Leave
