import { notification } from 'antd'
import '../styles/notification.css'

export const success = (title, message) => {
  notification.success({
    className: 'poping-notification',
    message: title,
    description: message,
  })
}
export const info = (title, message) => {
  notification.info({
    className: 'poping-notification',
    message: title,
    description: message,
  })
}

export const warning = (title, message) => {
  notification.warning({
    className: 'poping-notification',
    message: title,
    description: message,
  })
}

export const error = (title, message) => {
  notification.error({
    className: 'poping-notification',
    message: title,
    description: message,
  })
}
