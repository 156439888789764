import React from 'react'
import { Typography } from 'antd'

// icons
import { FileProtectOutlined } from '@ant-design/icons'

const HeaderTitle = ({ subHeaderSize }) => {
  return (
    <>
      <Typography.Title
        level={4}
        style={{
          marginTop: '8px',
          marginLeft: '5px',
        }}>
        <FileProtectOutlined /> สลิปเงินเดือน
      </Typography.Title>
      <span
        style={{
          marginLeft: '5px',
          color: '#ccc',
          fontSize: subHeaderSize,
        }}>
        ตรวจสอบรายได้รายหักในแต่ละรอบเดือน สามารถพิมพ์เอกสารและดูย้อนหลังได้
      </span>
    </>
  )
}

export default HeaderTitle
