import dotenv from 'dotenv'
import axios from 'axios'
import { localStorageDecrypt } from './Storage'

dotenv.config()
// axios.defaults.baseURL: "https://pctstamp.com:8443/api/v1",
// axios.defaults.baseURL = 'http://49.0.65.111:9001/api/v2/'
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common = {
  Authorization: `bearer ${localStorageDecrypt('token')}`,
}

export default axios
