import { useState } from 'react'
import { Menu, Dropdown, Button, Col, Modal } from 'antd'
import { DownOutlined, LeftOutlined, LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import '../../styles/munuItemHeader.css'

// context
import { useAuth } from '../../context/Auth'
const IconStyle = {
  fontSize: '10px',
}

const MenuItemHeader = () => {
  const { user, setLogout } = useAuth()
  const [isVisible, setIsVisible] = useState(false)

  const handleLogout = () => {
    setLogout()
  }

  function countDown() {
    let secondsToGo = 3
    const modal = Modal.warning({
      icon: <LoadingOutlined />,
      centered: true,
      className: 'modal-logout',
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      title: 'กำลังออกจากระบบ...',
      content: ``,
    })
    setTimeout(() => {
      handleLogout()
      modal.destroy()
    }, secondsToGo * 1000)
  }

  const userMenu = (
    <Menu>
      <Menu.Item key='accounts-profiles'>
        <Link to='/accounts/profiles'>ข้อมูลของฉัน</Link>
      </Menu.Item>
      <Menu.Item key='accounts/change-password'>
        <Link to='/accounts/change-password'>เปลี่ยนรหัสผ่าน</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='logout' onClick={countDown}>
        ออกจากระบบ
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <Col className='col__dropdown__btn'>
        <Dropdown
          overlay={userMenu}
          trigger={['click']}
          onVisibleChange={(e) => setIsVisible(e)}>
          <Button style={{ border: 0 }}>
            <span
              style={{
                fontSize: '12px',
              }}>{`${user.PersonCode} - ${user.FnameT} ${user.LnameT}`}</span>{' '}
            {isVisible ? (
              <DownOutlined style={IconStyle} />
            ) : (
              <LeftOutlined style={IconStyle} />
            )}
          </Button>
        </Dropdown>
      </Col>
    </>
  )
}

export default MenuItemHeader
