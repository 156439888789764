import React, { useState, useEffect, createContext, useContext } from 'react'
import axios from '../library/Axios'
import { localStorageEncrypt, localStorageDecrypt } from '../library/Storage'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext()

let initialUser = {
  Company_NameT: '',
  Company_NameE: '',
  ID_Company: 0,
  PersonID: 0,
  Pws: '',
  PersonCode: '',
  PersonCardID: '',
  FnameT: '',
  LnameT: '',
  FnameE: '',
  LnameE: '',
  IdentityID: '',
  Cmb1NameT: '',
  Cmb1NameE: '',
  Cmb2NameT: '',
  Cmb2NameE: '',
  Cmb3NameT: '',
  Cmb3NameE: '',
  Cmb4NameT: '',
  Cmb4NameE: '',
  Cmb5NameT: '',
  Cmb5NameE: '',
  Cmb6NameT: '',
  Cmb6NameE: '',
  PositionNameT: '',
  PositionNameE: '',
  EmpTypeNameT: '',
  AccountNO: '',
  BankNameT: '',
}
function AuthContextProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState(initialUser)

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  const navigate = useNavigate()
  const fetch = () => {
    const token = localStorageDecrypt('token')
    if (token !== null) {
      axios
        .get('/auth/me')
        .then((response) => {
          const { PersonCode, PersonID, PersonCardID } = response.data

          // set id to local storage
          localStorageEncrypt('personCode', PersonCode)
          localStorageEncrypt('personId', PersonID)
          localStorageEncrypt('personCardId', PersonCardID)

          // set log in
          setLoggedIn(true)
          setUser(response.data)
        })
        .catch((err) => {
          setLogout()
          navigate('/')
        })
    } else {
      setLogout()
    }
  }

  const setLogout = () => {
    setUser(initialUser)
    setLoggedIn(false)

    localStorage.clear()
    navigate('/')
  }
  const value = {
    user,
    setUser,
    loggedIn,
    setLoggedIn,
    setLogout,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
export const AuthConsumer = AuthContext.Consumer
export default AuthContextProvider
