import React from 'react'
import { Row, Col, Card, Typography, Divider, Tag } from 'antd'
import {
  UserOutlined,
  BankOutlined,
  SafetyOutlined,
  IdcardOutlined,
  FieldNumberOutlined,
  ContactsOutlined,
  ApartmentOutlined,
} from '@ant-design/icons'

// style
import '../../styles/profiles.css'

// context
import { useAuth } from '../../context/Auth'
import ResponsiveContextProvider, {
  ResponsiveConsumer,
} from '../../context/responsive/Profiles'

// helpers
import { IdentityIdFormat, BankAccountFormat } from '../../helpers/UserData'

const Profiles = () => {
  const { user } = useAuth()

  return (
    <ResponsiveContextProvider>
      <ResponsiveConsumer>
        {({
          headerFontSize,
          titleFontSize,
          titleSubHeaderSize,
          titleSubFontSize,
          titleColspan,
          detailColspan,
        }) => {
          return (
            <div
              style={{
                backgroundColor: '#fff',
                padding: '10px',
                borderRadius: '8px',
              }}>
              <Row>
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      marginTop: '8px',
                      marginLeft: '5px',
                      fontSize: headerFontSize,
                    }}>
                    <UserOutlined /> บัญชีของฉัน
                  </Typography.Title>
                  <span
                    style={{
                      color: '#ccc',
                      fontSize: titleSubHeaderSize,
                    }}>
                    ตรวจสอบข้อมูลส่วนตัวของพนักงาน
                    หากผิดพลาดสามารถแจ้งฝ่ายบุคคลทำการเปลี่ยนแปลงให้ภายหลัง
                  </span>
                </Col>
                <Divider style={{ margin: '10px 0' }} />
              </Row>
              <Row gutter={[12, 12]} style={{ marginBottom: '12px' }}>
                <Col span={24}>
                  <Card
                    className='profiles-card'
                    style={{ borderRadius: '8px' }}>
                    <Row justify='space-between' gutter={[8, 8]}>
                      <Col span={titleColspan}>
                        <Typography.Title
                          level={5}
                          style={{ fontSize: titleFontSize }}>
                          <ApartmentOutlined /> บริษัท
                        </Typography.Title>
                      </Col>
                      <Col span={detailColspan}>
                        <Tag color='default'>{`${user.Company_NameT}`} </Tag>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '15px 0' }} />
                    <Row justify='space-between' gutter={[8, 8]}>
                      <Col span={titleColspan}>
                        <Typography.Title
                          level={5}
                          style={{ fontSize: titleFontSize }}>
                          <FieldNumberOutlined /> รหัสพนักงาน
                        </Typography.Title>
                      </Col>
                      <Col span={detailColspan}>
                        <Tag color='default'>{`${user.PersonCode}`} </Tag>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '15px 0' }} />
                    <Row justify='space-between' gutter={[8, 8]}>
                      <Col span={titleColspan}>
                        <Typography.Title
                          level={5}
                          style={{ fontSize: titleFontSize }}>
                          <ContactsOutlined /> ชื่อ-สกุล
                        </Typography.Title>
                      </Col>
                      <Col span={detailColspan}>
                        <Tag color='default'>
                          {`${user.FnameT} ${user.LnameT}`}{' '}
                        </Tag>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '15px 0' }} />
                    <Row justify='space-between' gutter={[8, 8]}>
                      <Col span={titleColspan}>
                        <Typography.Title
                          level={5}
                          style={{ fontSize: titleFontSize }}>
                          <IdcardOutlined /> เลขที่บัตรประชาชน
                        </Typography.Title>
                      </Col>
                      <Col span={detailColspan}>
                        <Tag color='default' style={{ letterSpacing: '1px' }}>
                          {IdentityIdFormat(user.IdentityID)}
                        </Tag>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '15px 0' }} />
                    <Row justify='space-between' gutter={[8, 8]}>
                      <Col span={titleColspan}>
                        <Typography.Title
                          level={5}
                          style={{ fontSize: titleFontSize }}>
                          <SafetyOutlined /> เลขบัญชีธนาคาร
                        </Typography.Title>
                      </Col>
                      <Col span={detailColspan}>
                        <Tag color='default' style={{ letterSpacing: '1px' }}>
                          {BankAccountFormat(user.AccountNO)}
                        </Tag>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '15px 0' }} />
                    <Row justify='space-between' gutter={[8, 8]}>
                      <Col span={titleColspan}>
                        <Typography.Title
                          level={5}
                          style={{ fontSize: titleFontSize }}>
                          <BankOutlined /> ชื่อธนาคาร
                        </Typography.Title>
                      </Col>
                      <Col span={detailColspan}>
                        <Tag color='default'>{user.BankNameT} </Tag>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          )
        }}
      </ResponsiveConsumer>
    </ResponsiveContextProvider>
  )
}

export default Profiles
