import React from 'react'
import { Card, Row, Typography } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'
import { getUTCTime } from '../../helpers/DateTime'

// context
import { useResponsive } from '../../context/responsive/Home'

const RecentStampCard = ({ data, cardStyle, iconColors }) => {
  const { opacityCardIcon } = useResponsive()
  return (
    <Card style={cardStyle} className='stat__card__dashboard'>
      <Row>
        <span>ลงเวลาล่าสุด</span>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <ClockCircleOutlined
          style={{
            fontSize: '60px',
            position: 'absolute',
            opacity: opacityCardIcon,
            bottom: 10,
            right: 10,
            color: iconColors,
            zIndex: 0,
          }}
        />
        <Typography.Title level={5} style={{ zIndex: 1 }}>
          {data ? getUTCTime(data) : '-'}
        </Typography.Title>
      </Row>
    </Card>
  )
}

export default RecentStampCard
