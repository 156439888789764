import React, { useEffect, useState } from 'react'
import MenuItemHeader from './MenuItemHeader'
import { Row, Col, Button, Layout } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import { useSiderContext } from '../../App'
import { useWindowSize } from '../../hooks/windowSize'

const { Header } = Layout
const iconStyle = {
  fontSize: '20px',
}
const Headers = () => {
  const width = useWindowSize().width
  const { collapsed, setCollapsed } = useSiderContext()
  const [hideContent, setHideContent] = useState(false)
  useEffect(() => {
    if (width <= 600 && !collapsed) {
      setHideContent(true)
    } else {
      setHideContent(false)
    }
    // eslint-disable-next-line
  }, [width, collapsed])
  return (
    <Header
      style={{
        width: '100%',
        backgroundColor: '#fff',
        height: '50px',
      }}>
      <Row
        justify='space-between'
        style={{ verticalAlign: 'middle', height: 'inherit' }}>
        <Col>
          <Button
            onClick={(e) => {
              setCollapsed((prev) => !prev)
            }}
            style={{ marginLeft: '5px', borderRadius: '8px', border: '0' }}>
            {collapsed ? (
              <MenuUnfoldOutlined style={iconStyle} />
            ) : (
              <MenuFoldOutlined style={iconStyle} />
            )}
          </Button>
        </Col>
        {hideContent ? '' : <MenuItemHeader />}
      </Row>
    </Header>
  )
}

export default Headers
